import { Component, OnInit } from '@angular/core';
import { Event } from '../model/event';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-modify-event',
  templateUrl: './modify-event.page.html',
  styleUrls: ['./modify-event.page.scss'],
})
export class ModifyEventPage implements OnInit {
title: string;
topic: string;
location: string;
start: Date;
end: Date;
contact: string;
type: string;
link: string;
id:string;
saveEvent() {
  this.databaseService.modifyEvent({
    title: this.title,
    topic: this.topic,
    location: this.location,
    link: this.link,
    start: new Date(this.start),
    end: new Date(this.end),
    contact: this.contact,
    type: this.type,
    id: this.id
  }as Event).then(() => this.modalController.dismiss());
}
closePopup() {
  this.modalController.dismiss();
}

constructor(private modalController: ModalController,
  public databaseService: DatabaseService) { }

  ngOnInit() {
  }

}
