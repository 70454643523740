import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-modify-rule',
  templateUrl: './modify-rule.page.html',
  styleUrls: ['./modify-rule.page.scss'],
})
export class ModifyRulePage implements OnInit {
  type: string;
  points: number;
  name: string;
  short: string;
  id:string
  constructor(private modalController: ModalController,
    public databaseService: DatabaseService) { }

  ngOnInit() {
  }

  closePopup() {
    this.modalController.dismiss();
  }

  saveRule() {
    if(this.type == "level"){
      this.databaseService.saveLevel(this.name, this.points, this.id).then(() => this.modalController.dismiss());;
    }else{
      this.databaseService.saveCategory(this.name, this.points, this.short, this.id).then(() => this.modalController.dismiss());;
    }
  }

}
